import useBalance from "./useBalance";

const useBalanceCheck = ({ raceMinBet, cb = () => {} }: { raceMinBet: Ref<number>; cb?: () => void }) => {
	const bet = ref(0);
	const betIsLow = computed(() => !!bet.value && bet.value < raceMinBet.value);
	const { balance } = useBalance({
		options: { immediate: false }
	});

	watch(
		() => balance.value?.data,
		(val, oldVal) => {
			const oldEntries = oldVal?.entries || 0;
			const newEntries = val?.entries || 0;

			if (oldVal && val && oldEntries > newEntries) {
				bet.value = oldEntries - newEntries;

				if (oldEntries - newEntries < raceMinBet.value) {
					cb();
				}
			}
		}
	);

	return {
		betIsLow
	};
};

export default useBalanceCheck;
