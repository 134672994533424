<script setup lang="ts">
defineProps<{
	isActive?: boolean;
}>();

const { t } = useT();
const { isSweepStakes } = useSwitchMode();
const { game } = useGameModal();
const { activeStatus, durationLeft, tournamentData, isSubscribed } = useRace({
	timerFormat: "HH[:]mm[:]ss"
});
const { betIsLow } = useBalanceCheck({ raceMinBet: toRef(() => tournamentData.value?.data?.betMin || 0) });

const active = ref("tournament");
const swipeStarted = ref(false);
const currentIndex = ref(0);
const activeTimer = ref<ReturnType<typeof setTimeout>>();

const minLvlBet = computed(() => `${tournamentData.value?.data?.betMin} ${isSweepStakes.value ? t("CC") : t("FC")}`);
const isTournamentGame = computed(() => {
	if (game.value && game.value?.game) {
		return tournamentData.value?.data?.isTournamentGame;
	}

	return false;
});

const variants = computed(() => {
	if (!activeStatus.value) {
		return ["tournament"];
	}

	if (betIsLow.value && isSweepStakes.value) {
		return ["minPlay"];
	}

	if (isTournamentGame.value && !isSubscribed.value) {
		return ["tournament", "joinRace"];
	}

	return ["tournament", "raceInfo", "raceInfoExt", "timeToEnd"];
});

const startSlide = () => {
	if (currentIndex.value >= variants.value.length) {
		currentIndex.value = 1;
	} else {
		currentIndex.value += 1;
	}

	active.value = variants.value[currentIndex.value - 1];

	if (variants.value.length === 1 && currentIndex.value === 1) {
		clearTimeout(activeTimer.value);
		return;
	}

	if (swipeStarted.value) {
		activeTimer.value = setTimeout(startSlide, 4000);
	}
};

watch([() => variants.value.length, isSweepStakes], () => {
	clearTimeout(activeTimer.value);
	activeTimer.value = setTimeout(startSlide, 1000);
});

onMounted(() => {
	swipeStarted.value = true;
	activeTimer.value = setTimeout(startSlide, 2000);
});

onUnmounted(() => {
	clearTimeout(activeTimer.value);
});
</script>

<template>
	<div class="race-progress">
		<div :class="['race-wrapper', active === 'joinRace' ? 'join-now' : '']">
			<Transition name="slide-up">
				<NuxtIcon
					v-if="active === 'tournament'"
					:name="isActive ? '20/race-active' : '20/race'"
					filled
					class="race-icon"
				/>
			</Transition>

			<Transition name="slide-up">
				<div v-if="active === 'timeToEnd'" class="item timer">
					<NuxtIcon name="14/time-gradient" filled class="time-icon" />
					<AText
						variant="trieste"
						gradient="var(--goeteborg)"
						class="time-text"
						:modifiers="['condensed', 'extrabold', 'nowrap']"
					>
						<span>{{ durationLeft }}</span>
					</AText>
				</div>
			</Transition>

			<Transition name="slide-up">
				<div v-if="active === 'minPlay'" class="item min-play">
					<AText variant="trieste" class="text-coro" :modifiers="['condensed', 'uppercase', 'bold']">
						{{ minLvlBet }}
					</AText>
					<AText variant="trieste" class="text-croydon" :modifiers="['condensed', 'center', 'uppercase', 'bold']">
						<i18n-t keypath="min play{key}lvl">
							<template #key><br /></template>
						</i18n-t>
					</AText>
				</div>
			</Transition>

			<Transition name="slide-up">
				<div v-if="active === 'joinRace'" class="item">
					<NuxtIcon name="14/join" filled class="join-icon" />
					<AText variant="texas" class="text-сirebon" :modifiers="['center', 'uppercase', 'bold']">
						<i18n-t keypath="join{key}now">
							<template #key><br /></template>
						</i18n-t>
					</AText>
				</div>
			</Transition>

			<Transition name="slide-up">
				<div v-if="active === 'raceInfo'" class="item">
					<AText variant="ternopil" class="text-coro" :modifiers="['uppercase', 'bold']">
						{{ tournamentData?.playerData?.place ?? 0 }}
					</AText>
					<AText variant="texas" class="text-coro" :modifiers="['uppercase']">
						{{ t("rank") }}
					</AText>
				</div>
			</Transition>

			<Transition name="slide-up">
				<div v-if="active === 'raceInfoExt'" class="item">
					<AText variant="ternopil" class="text-coro" :modifiers="['uppercase', 'bold']">
						{{ (tournamentData?.data?.betLimit ?? 0) - (tournamentData?.playerData?.roundsPlayed ?? 0) }}
					</AText>
					<AText variant="texas" class="text-coro" :modifiers="['uppercase']">
						{{ t("plays") }}
					</AText>
				</div>
			</Transition>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.race-progress {
	margin-top: gutter(-0.25);
	margin-left: gutter(-0.25);
	width: 44px;
	height: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}
.race-wrapper {
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	position: relative;
	transition: rotate 0.2s;
	border: 2px solid var(--chitungwiza);

	&.join-now {
		background: var(--gainesville);
		border: none;

		.item {
			background-color: var(--carabanchel);
			border-radius: 50%;
			width: 36px;
			height: 36px;
			top: 2px;
			left: 2px;
		}
	}

	.item {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		&.timer {
			gap: gutter(0.25);
			padding-bottom: gutter(0.5);
		}

		&.min-play {
			justify-content: flex-end;
			gap: gutter(0.25);
		}

		:deep(svg) {
			margin: 0;
		}
	}

	.race-icon {
		font-size: 22px;
	}

	.time-icon {
		font-size: 12px;
	}

	.join-icon {
		font-size: 12px;
		position: absolute;
		bottom: 0;
		right: 0;
	}
}

.slide-up-enter-active,
.slide-up-leave-active,
.slide-enter-active,
.slide-leave-active {
	transition: all 0.3s ease-out;
}

.slide-up-enter-from {
	opacity: 0;
	transform: translateY(30px);
}

.slide-up-leave-to {
	opacity: 0;
	transform: translateY(-30px);
}

.slide-enter-from,
.slide-leave-to {
	opacity: 0;
	transform: translateY(-40px);
}
</style>
